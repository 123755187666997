<template>
  <ion-page>
    <ion-content :scroll-events="true">
      <div id="container" class="marginBottom4px">
        <ion-card>
          <ion-card-header class="ion-margin-top">
            <ion-card-title style="font-size: x-large">
              Sugerencias
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-item>
              <ion-label position="floating">Nombre</ion-label>
              <ion-input
                v-model="nombre"
                type="text"
                placeholder="Nombre y Apellido"
                inputmode="text"
                autocomplete="off"
                class="ion-text-capitalize"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating">Email</ion-label>
              <ion-input
                v-model="email"
                autocapitalize="off"
                placeholder="Email"
                type="email"
                inputmode="email"
                autocomplete="email"
              ></ion-input>
            </ion-item>
            <ion-item class="ion-margin-top" fill="solid" color="light">
              <ion-label position="stacked">
                Sugerencia
              </ion-label>
              <ion-textarea
                v-model="sugerencia"
                spellcheck="true"
                autoGrow="true"
                autocapitalize="off"
                rows="6"
              ></ion-textarea>
            </ion-item>
            <ion-row class="ion-float-right">
              <ion-col size="auto">
                <ion-button
                  fill="outline"
                  class="ion-margin-top fontSmallSize"
                  color="medium"
                  @click="volver"
                >
                  Cancelar
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button class="ion-margin-top" @click="enviarSugerencia">
                  Enviar
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonRow,
  IonCol,
  IonButton,
  alertController
} from "@ionic/vue";
import { mapActions, mapState, mapGetters } from "vuex";
import http from "@/api/http";
import isEmail from "validator/es/lib/isEmail";

export default {
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonRow,
    IonCol,
    IonButton
  },
  data: () => ({
    nombre: "",
    email: "",
    sugerencia: ""
  }),
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"])
  },
  async mounted() {
    await this.getMe();
    await this.dataSync();
  },
  watch: {
    user() {
      this.dataSync();
    }
  },
  methods: {
    ...mapActions(["getMe"]),
    async dataSync() {
      this.nombre = this.user.nombre + " " + this.user.apellido;
      this.email = this.user.email;
      this.sugerencia = "";
    },

    async enviarSugerencia() {
      try {
        if (isEmail(this.email)) {
          await http.post("/api/send_suggestion", {
            nombre: this.nombre,
            email: this.email,
            sugerencia: this.sugerencia
          });
          const alert = await alertController.create({
            header: "Sugerencias",
            message: "La sugerencia ha sido enviada con éxito.",
            buttons: [
              {
                text: "Aceptar",
                handler: async () => {
                  if (this.isAdmin) {
                    this.$router.push({ name: "admin-productores" });
                  }
                  this.$router.push({ name: "persona-lotes" });
                }
              }
            ]
          });
          return alert.present();
        }
        const alert = await alertController.create({
          header: "Error de email",
          message:
            "El email ingresado no es válido, intente ingresando otro con el formato permitido.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      } catch (rta) {
        return rta;
      }
    },

    volver() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.leftMargin2vh {
  margin-left: 2vh;
}

.margen8pxArriba {
  margin-top: 8px;
}

.marginBottom4px {
  margin-bottom: 4px;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}
</style>
